import { useMemo } from 'react';
import {
  TListAllAddressesQuery,
  TListAllAddressesQueryVariables,
  useListAllAddressesLazyQuery,
} from '../../generated/graphql';
import { useMap } from '../shared/useMap';
import { useQueryAll } from '../shared/useQueryAll';
import _ from 'lodash';

// LISTS

export const useQueryAllAddresses = () => {
  const { data, error, loading } = useQueryAll<TListAllAddressesQuery, TListAllAddressesQueryVariables>({
    useQuery: useListAllAddressesLazyQuery,
    variables: {},
  });

  const addresses = useMemo(() => {
    return _.compact(data?.listAddresses?.items);
  }, [data?.listAddresses?.items]);

  return {
    data: addresses,
    error,
    loading,
  };
};

export type TAdressType = ReturnType<typeof useQueryAllAddresses>['data'][number];

export const useAllAddressesMap = () => {
  const { data, error, loading } = useQueryAllAddresses();

  const addressesMap = useMap<(typeof data)[number]>(data);

  return {
    data: addressesMap,
    error,
    loading: loading || addressesMap == null,
  };
};
