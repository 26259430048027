import { TEventTypes } from '../../generated/graphql';

export const translateEventType = (type: TEventTypes) => {
  switch (type) {
    case TEventTypes.Birthday:
      return 'Birthday';
    case TEventTypes.WorkAnniversary:
      return 'Work Anniversary';
    case TEventTypes.NewHire:
      return 'New Hire';
  }
};
