import { TConnectionItem, subscribe as subscribeBase } from '@chocolate-soup-inc/cs-api-consumer-utils';
import { client } from '../../config/apollo/api';
import { onSubscriptionData, subscribe } from '../../config/apollo/cache';
import {
  EventFieldsFragmentDoc,
  GetEventDocument,
  OnAnyDependantChangedDocument,
  OnAnyEventChangedDocument,
  TAddressParentTypes,
  TBasicEventFieldsFragment,
  TDeliveryMethod,
  TEventFieldsFragment,
  TEventTypes,
  TGetEventQuery,
  TGetEventQueryVariables,
  TListNotSkippedEventsWithoutGiftQuery,
  TListNotSkippedEventsWithoutGiftQueryVariables,
  TListSkippedEventsQuery,
  TListSkippedEventsQueryVariables,
  TOnAnyEventChangedSubscription,
  TOnAnyEventChangedSubscriptionVariables,
  useGetEventLazyQuery,
  useListNotSkippedEventsWithoutGiftLazyQuery,
  useListSkippedEventsLazyQuery,
} from '../../generated/graphql';
import { serializeError } from 'serialize-error';
import { useEffect, useMemo } from 'react';
import { useFragmentOrFetch } from '../shared/useFragmentOrFetch';
import { useQueryAll } from '../shared/useQueryAll';
import _ from 'lodash';
import { useAllCompaniesMap } from '../companies/queries';
import { useAllEmployeesMap, useSubscribeToEmployeeChanged } from '../employees/queries';
import { useAllDependantsMap } from '../dependants/queries';
import subDays from 'date-fns/subDays';
import { useAllAddressesMap } from '../addresses/queries';
import { calculatedShippingDate } from '../shared/shippingDate';

// SUBSCRIBE

export const onEventSubscriptionData = (
  data?: TOnAnyEventChangedSubscription,
  vars?: TOnAnyEventChangedSubscriptionVariables,
) => {
  const { companyId, id, _deleted } = data?.onAnyEventChanged || {};

  if (_deleted) {
    return onSubscriptionData(data as Record<string, any>, vars);
  } else if (companyId && id) {
    client
      .query<TGetEventQuery, TGetEventQueryVariables>({
        query: GetEventDocument,
        variables: {
          id,
          companyId,
        },
      })
      .then((response) => {
        const eventData = response?.data?.getEvent;

        if (eventData) {
          const subscriptionData = {
            onAnyEventChanged: eventData as TConnectionItem,
          };

          // CHANGED DATA FOR QUERIES THAT DEPEND ON NO VARIABLE (SINCE VARS SHOULD BE AN EMPTY OBJECT IN THIS SITUATION)
          onSubscriptionData(subscriptionData, vars);
        }
      })
      .catch((error) => {
        console.error('Error', serializeError(error));
      });
  }
};

let onAnyEventChangedSubscribed = false;

export const useSubscribeToEventChanges = () => {
  useSubscribeToEmployeeChanged();

  useEffect(() => {
    subscribe({
      query: OnAnyDependantChangedDocument,
      variables: {},
    });
  }, []);

  useEffect(() => {
    if (!onAnyEventChangedSubscribed) {
      subscribeBase({
        client,
        query: OnAnyEventChangedDocument,
        onSubscriptionData: (data, vars) => {
          return onEventSubscriptionData(
            data as TOnAnyEventChangedSubscription,
            vars as TOnAnyEventChangedSubscriptionVariables,
          );
        },
        variables: {},
      });

      onAnyEventChangedSubscribed = true;
    }
  }, []);
};

// LIST

export const useFullEvents = <T extends TBasicEventFieldsFragment>(events: T[]) => {
  const { data: companiesMap, error: companiesError, loading: companiesLoading } = useAllCompaniesMap();

  const { data: employeesMap, error: employeesError, loading: employeesLoading } = useAllEmployeesMap();

  const { data: dependantsMap, error: dependantsError, loading: dependantsLoading } = useAllDependantsMap();

  const { data: addressesMap, error: addressesError, loading: addressesLoading } = useAllAddressesMap();

  const fullEvents = useMemo(() => {
    return events.map((e) => {
      let recipient: (typeof employeesMap)[string] | (typeof dependantsMap)[string] | undefined;

      if (e.recipientId != null) {
        const [tableName, recipientId] = e.recipientId.split('|');

        if (tableName === process.env.REACT_APP_DEPENDANTS_TABLE_NAME) {
          recipient = dependantsMap[recipientId];
        } else if (tableName === process.env.REACT_APP_EMPLOYEES_TABLE_NAME) {
          recipient = employeesMap[recipientId];
        }
      }

      const address = e.addressId ? addressesMap[e.addressId] : undefined;

      return {
        ...e,
        company: e.companyId ? companiesMap[e.companyId] : undefined,
        employee: e.employeeId ? employeesMap[e.employeeId] : undefined,
        address,
        recipient: recipient,
        shippingDate: calculatedShippingDate(e.eventDate, address),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressesMap, companiesMap, dependantsMap, employeesMap, JSON.stringify(events)]);

  return {
    data: fullEvents,
    error: companiesError || employeesError || dependantsError || addressesError,
    loading: companiesLoading || employeesLoading || dependantsLoading || addressesLoading,
  };
};

export type TEventType<T extends TBasicEventFieldsFragment = TBasicEventFieldsFragment> = ReturnType<
  typeof useFullEvents<T>
>['data'][number];

export const useQueryAllUpcomingEvents = () => {
  useSubscribeToEventChanges();

  const { data, error, loading } = useQueryAll<
    TListNotSkippedEventsWithoutGiftQuery,
    TListNotSkippedEventsWithoutGiftQueryVariables
  >({
    useQuery: useListNotSkippedEventsWithoutGiftLazyQuery,
    variables: {},
  });

  const {
    data: fullEvents,
    error: fullError,
    loading: fullLoading,
  } = useFullEvents(_.compact(data?.listNotSkippedEventsWithoutGift.items));

  const events = useMemo(() => {
    return fullEvents
      .filter(
        (e) =>
          e.giftId == null &&
          e.skippedAt == null &&
          !e.isPaused &&
          e.company?.isLive &&
          (e.company?.endDate == null || new Date(e.company?.endDate).getTime() >= new Date().getTime()) &&
          !e.employee?.deletedAt,
      )
      .sort((a, b) => {
        return new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime();
      });
  }, [fullEvents]);

  return {
    data: events,
    error: error || fullError,
    loading: loading || fullLoading,
  };
};

export const useNewHiresUpcomingEvents = () => {
  const { data, error, loading } = useQueryAllUpcomingEvents();

  const newHiresUpcomingEvents = useMemo(() => {
    return data.filter((e) => e.type === TEventTypes.NewHire);
  }, [data]);

  return {
    data: newHiresUpcomingEvents,
    error,
    loading,
  };
};

export const useSubscriptionUpcomingEvents = () => {
  const { data, error, loading } = useQueryAllUpcomingEvents();

  const subscriptionUpcomingEvents = useMemo(() => {
    return data.filter((e) => e.type !== TEventTypes.NewHire);
  }, [data]);

  return {
    data: subscriptionUpcomingEvents,
    error,
    loading,
  };
};

export const useUpcomingSubscriptionOfficeEvents = () => {
  const { data, error, loading } = useSubscriptionUpcomingEvents();

  const officeEvents = useMemo(() => {
    return data.filter((e) => {
      if (e.address != null) {
        return e.address?.parentType !== TAddressParentTypes.Employee;
      } else {
        return e.company?.deliveryMethod !== TDeliveryMethod.Home;
      }
    });
  }, [data]);

  return {
    data: officeEvents,
    error,
    loading,
  };
};

export const useUpcomingSubscriptionHomeEvents = () => {
  const { data, error, loading } = useSubscriptionUpcomingEvents();

  const homeEvents = useMemo(() => {
    return data.filter((e) => {
      if (e.address != null) {
        return e.address?.parentType === TAddressParentTypes.Employee;
      } else {
        return e.company?.deliveryMethod === TDeliveryMethod.Home;
      }
    });
  }, [data]);

  return {
    data: homeEvents,
    error,
    loading,
  };
};

export type TEventWithSubRows = TEventType & { subRows?: TEventType[] };

export const useGroupedUpcomingSubscriptionHomeEvents = () => {
  const { data, error, loading } = useUpcomingSubscriptionHomeEvents();

  const [groupedHomeEvents, flatHomeEvents] = useMemo(() => {
    let grouped: TEventWithSubRows[] = [];
    const flat: typeof data = [];

    Object.values(_.groupBy(data, (e) => e.addressId || JSON.stringify(e))).map((items) => {
      const newItems: TEventWithSubRows[] = [];

      for (const item of items) {
        const itemDate = new Date(item.eventDate);
        itemDate.setUTCHours(0, 0, 0, 0);
        const lowerDate = subDays(itemDate, 14);

        const firstMatchingDateIndex = newItems.findIndex((newItem) => {
          const d = new Date(newItem.eventDate);
          d.setUTCHours(0, 0, 0, 0);
          return d.getTime() >= lowerDate.getTime() && d.getTime() <= itemDate.getTime();
        });

        const firstMatchingDate = newItems[firstMatchingDateIndex];

        if (firstMatchingDate) {
          if (firstMatchingDate.subRows == null) firstMatchingDate.subRows = [];

          const eventData = { ...item, groupedToEventId: firstMatchingDate.id };
          firstMatchingDate.subRows.push(_.cloneDeep(eventData));
          if (firstMatchingDate.groupedToEventId == null) {
            const flatIndex = flat.findIndex((e) => e.id === firstMatchingDate.id);
            if (flatIndex > -1) {
              flat[flatIndex].groupedToEventId = firstMatchingDate.id;
            }
            newItems[firstMatchingDateIndex].groupedToEventId = firstMatchingDate.id;
          }
          flat.push(_.cloneDeep(eventData));
        } else {
          newItems.push(_.cloneDeep(item));
          flat.push(_.cloneDeep(item));
        }
      }

      grouped = grouped.concat(newItems);
    });

    return [
      grouped.sort((a, b) => {
        const shippingDateDiff = new Date(a.shippingDate).getTime() - new Date(b.shippingDate).getTime();
        if (shippingDateDiff === 0) {
          return new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime();
        } else {
          return shippingDateDiff;
        }
      }),
      flat,
    ];
  }, [data]);

  return {
    data: groupedHomeEvents,
    flatData: flatHomeEvents,
    error,
    loading,
  };
};

export const useQueryAllSkippedEvents = () => {
  useSubscribeToEventChanges();

  const { data, error, loading } = useQueryAll<TListSkippedEventsQuery, TListSkippedEventsQueryVariables>({
    useQuery: useListSkippedEventsLazyQuery,
    variables: {},
  });

  const {
    data: fullEvents,
    error: fullError,
    loading: fullLoading,
  } = useFullEvents(_.compact(data?.listSkippedEvents.items));

  const events = useMemo(() => {
    return fullEvents.filter((e) => e.skippedAt != null && e.giftId == null);
  }, [fullEvents]);

  return {
    data: events,
    error: error || fullError,
    loading: loading || fullLoading,
  };
};

// ITEMS

export const useFragmentOrFetchEvent = (variables: TGetEventQueryVariables) => {
  useSubscribeToEventChanges();

  const { data, error, loading } = useFragmentOrFetch<
    TEventFieldsFragment,
    TGetEventQuery,
    TGetEventQueryVariables,
    TOnAnyEventChangedSubscriptionVariables
  >({
    useLazyQuery: useGetEventLazyQuery,
    variables,
    fragmentDoc: EventFieldsFragmentDoc,
    __typename: 'Event',
  });

  const { data: events, error: fullError, loading: fullLoading } = useFullEvents(_.compact([data]));

  return {
    data: events[0],
    error: error || fullError,
    loading: loading || fullLoading,
  };
};
