import { ExtendedFAB, Icon, Modal, Link, TableInner, Tooltip } from '@chocolate-soup-inc/cs-frontend-components';
import { CellContext, RowSelectionState } from '@tanstack/react-table';
import format from 'date-fns/format';
import _ from 'lodash';
import { quickScore } from 'quick-score';
import { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import { Filters, TFiltersProps } from '../../../components/filters/Filters';
import { TTablePageRef, TablePage } from '../../../components/tablePage/TablePage';
import {
  getShipmentAddressInfo,
  getShipDate,
  useShipmentAddressInfo,
  getEventDateString,
} from '../../../entities/shipments/shared';
import { TShipmentStatuses, useReshipShipmentMutation } from '../../../generated/graphql';
import styles from './ShipmentsTracking.module.scss';
import { FloatingActions } from '../../../components/floatingActions/FloatingActions';
import { serializeError } from 'serialize-error';
import { toast } from 'react-toastify';
import { TShipmentType, useQueryAllShippedShipments } from '../../../entities/shipments/queries';
import { TrackingDetailsModal } from '../TrackingDetailsModal/TrackingDetailsModal';
import { filterText } from './filters';
import { compareDateDesc } from './sorting';
import { useFilter } from '../../../contexts/filters';
import clsx from 'clsx';
import tableStyles from '../../../components/tablePage/TablePage.module.scss';

export const RecipientCell = (context: CellContext<TShipmentType, unknown>) => {
  const { addressTypeIcon, recipientName, readableAddressType } = useShipmentAddressInfo(context.cell.row.original);

  return (
    <div className={clsx(styles.recipientCell, tableStyles.tableSpacePrimary)}>
      {addressTypeIcon && readableAddressType && (
        <Tooltip autoPlacement={false} message={readableAddressType} placement='bottom'>
          <Icon filled={true} icon={addressTypeIcon} />
        </Tooltip>
      )}
      {recipientName}
    </div>
  );
};

export const TrackingCell = (context: CellContext<TShipmentType, unknown>) => {
  const { trackingNumber, trackingURL } = context.cell.row.original;

  return (
    <div className={clsx(tableStyles.tableSpacePrimary)}>
      {trackingNumber && trackingURL ? (
        <Link
          href={trackingURL}
          label={trackingNumber}
          icon={true}
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();
            window.open(trackingURL);
          }}
        />
      ) : (
        '-'
      )}
    </div>
  );
};

export const ShipmentsTracking = () => {
  const { filtersPageMode } = useFilter();
  useEffect(() => {
    filtersPageMode();
  }, []); //eslint-disable-line

  const { data: shipments, error, loading } = useQueryAllShippedShipments();

  const [selectedRows, setSelectedRows] = useState<string[]>();

  const tableRef = createRef<TTablePageRef>();

  const onRowSelectionChange = useCallback((rowSelection: RowSelectionState) => {
    setSelectedRows(Object.keys(rowSelection));
  }, []);

  const allCompanies = useMemo(() => {
    return _.uniqBy(_.compact(shipments.map((s) => s.company)), 'id');
  }, [shipments]);

  const companies = useMemo(() => {
    return _.uniq(_.compact(shipments.map((s) => allCompanies.find((c) => c.id === s.companyId))));
  }, [allCompanies, shipments]);

  const getRowId = useCallback((shipment: TShipmentType) => {
    return shipment.id;
  }, []);

  const [companyFilter, setCompanyFilter] = useState<string>();
  const [trackingNumberFilter, setTrackingNumberFilter] = useState<string>();
  const [statusFilter, setStatusFilter] = useState<string>();
  const [recipientFilter, setRecipientFilter] = useState<string>();

  const filters = useMemo(() => {
    const filtersList: TFiltersProps['filters'] = [];

    filtersList.push({
      includeEmptyOption: true,
      type: 'singleSelect',
      label: 'Company',
      name: 'companyId',
      onChange: (v) => {
        if (v == null) setCompanyFilter(undefined);
        else setCompanyFilter(v as string);
      },
      options: companies.map((c) => ({ label: c.name, value: c.id })),
      value: companyFilter,
    });

    filtersList.push({
      type: 'textInput',
      label: 'Recipient',
      name: 'recipientName',
      onChange: (v) => {
        if (v == null) setRecipientFilter(undefined);
        else setRecipientFilter(v as string);
      },
      value: recipientFilter,
    });

    filtersList.push({
      type: 'textInput',
      label: 'Tracking Number',
      name: 'trackingNumber',
      onChange: (v) => {
        if (v == null) setTrackingNumberFilter(undefined);
        else setTrackingNumberFilter(v as string);
      },
      value: trackingNumberFilter,
    });

    filtersList.push({
      includeEmptyOption: true,
      type: 'singleSelect',
      label: 'Status',
      name: 'status',
      onChange: (v) => {
        if (v == null) setStatusFilter(undefined);
        else setStatusFilter(v as TShipmentStatuses);
      },
      options: [
        {
          label: 'In transit',
          value: TShipmentStatuses.Shipped,
        },
        {
          label: 'Attention',
          value: TShipmentStatuses.Attention,
        },
        {
          label: 'Delivered',
          value: TShipmentStatuses.Delivered,
        },
        {
          label: 'Returned',
          value: TShipmentStatuses.Returned,
        },
        {
          label: 'Error',
          value: TShipmentStatuses.Error,
        },
      ],
      value: statusFilter,
    });

    return filtersList;
  }, [companies, companyFilter, recipientFilter, statusFilter, trackingNumberFilter]);

  const filteredShipments = useMemo(() => {
    return shipments.filter((s) => {
      if (companyFilter != null && s.companyId !== companyFilter) return false;

      if (
        trackingNumberFilter != null &&
        trackingNumberFilter !== '' &&
        (s.trackingNumber == null || quickScore(s.trackingNumber, trackingNumberFilter) < 0.7)
      ) {
        return false;
      }

      if (statusFilter != null && s.status !== statusFilter) return false;

      const { recipientName } = getShipmentAddressInfo({ shipment: s });
      if (recipientFilter && !filterText(recipientFilter, recipientName ?? '')) {
        return false;
      }

      return true;
    });
  }, [companyFilter, recipientFilter, shipments, statusFilter, trackingNumberFilter]);
  const sortedShipments = useMemo(() => {
    return [...filteredShipments].sort((a, b) => {
      const dateA = getShipDate(a);
      const dateB = getShipDate(b);
      return compareDateDesc(dateA, dateB);
    });
  }, [filteredShipments]);
  const [reshipShipment] = useReshipShipmentMutation();
  const [reshipLoading, setReshipLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [recipientGifts, setRecipientGifts] = useState<any[] | null | undefined>(null);
  const [shipment, setShipment] = useState<TShipmentType | null | undefined>(null);

  const openModalDisplay = (original: any | undefined) => {
    setShipment(original);
    setRecipientGifts(original.gifts);
    setIsModalOpen(true);
  };

  const reshipShipments = useCallback(() => {
    setReshipLoading(true);
    return Promise.all(
      (selectedRows || []).map((id) => {
        return new Promise((resolve, reject) => {
          const shipment = filteredShipments.find((s) => s.id === id);
          if (shipment == null) return resolve(undefined);

          return reshipShipment({
            variables: {
              id: shipment.id,
              companyId: shipment.companyId,
              version: shipment._version,
            },
          })
            .then(resolve)
            .catch(reject);
        });
      }),
    )
      .then(() => {
        toast.success('The shipments was successfully set to reship.');
      })
      .catch((error) => {
        console.error(serializeError(error));
        toast.error('There was an error trying to reship these shipments.');
      })
      .then(() => {
        tableRef.current?.resetConfirmationModal();
        setReshipLoading(false);
        setSelectedRows(undefined);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredShipments, reshipShipment, selectedRows, tableRef, tableRef.current]);

  const onReshipShipmentsClick = useCallback(() => {
    tableRef?.current?.setConfirmationProps({
      headline: `Reship ${selectedRows?.length} Shipments?`,
      supportingText: `Are you sure you want to reship these ${selectedRows?.length} shipments? By reshipping, they will be moved back to "Ready to Ship" and then it will be possible to print a new label for the same shipments.`,
      confirmLabel: 'Reship',
      onConfirmClick: reshipShipments,
      open: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reshipShipments, selectedRows?.length, tableRef, tableRef.current]);

  return (
    <TablePage
      dataLoading={loading}
      actionsLoading={reshipLoading}
      error={error}
      ref={tableRef}
      title='Shipments Tracking'
    >
      <Filters filters={filters} />
      <div className={styles.tableContainer}>
        <TableInner<TShipmentType>
          className={styles.tableContainer}
          data={sortedShipments}
          emptyText={shipments.length === 0 ? 'No shipments to be tracked.' : 'No shipments for the current filters'}
          expandable={false}
          fillSelectedRows={true}
          fixedHeader={true}
          forceExpanded={true}
          getRowId={getRowId}
          hideSubRowsSelect={true}
          hoverableRows={!reshipLoading}
          onRowSelectionChange={onRowSelectionChange}
          selectableRows={true}
          selectingChildSelectsFather={true}
          onRowClick={(e) => {
            openModalDisplay(e.original);
          }}
          columns={[
            {
              header: 'Company',
              cell: ({ cell }) => {
                return (
                  <div className={clsx(tableStyles.tableSpacePrimary)}>
                    <span>{allCompanies.find((c) => c.id === cell.row.original.companyId)?.name}</span>
                  </div>
                );
              },
            },
            {
              header: 'Recipient',
              cell: RecipientCell,
            },
            {
              header: 'Event Date',
              cell: ({ cell }) => {
                return (
                  <div className={clsx(tableStyles.tableSpaceTertiary)}>
                    <span>{getEventDateString(cell.row.original)}</span>
                  </div>
                );
              },
            },
            {
              header: 'Ship Date',
              cell: ({ cell }) => {
                const date = getShipDate(cell.row.original);
                return (
                  <div className={clsx(tableStyles.tableSpaceTertiary)}>
                    <span>{date ? format(date, 'do MMM, yyyy') : '-'}</span>
                  </div>
                );
              },
            },
            {
              header: 'Tracking Number',
              cell: TrackingCell,
            },
          ]}
        />
      </div>
      {selectedRows && selectedRows.length > 0 && (
        <FloatingActions>
          <ExtendedFAB
            label={`Reship ${selectedRows.length} shipments.`}
            leadingIcon='replay'
            loading={reshipLoading}
            onClick={onReshipShipmentsClick}
            variant='surface'
          />
        </FloatingActions>
      )}
      <Modal
        headline='Tracking Details'
        open={isModalOpen}
        size='large'
        closeModal={() => {
          setIsModalOpen(false);
        }}
      >
        <TrackingDetailsModal shipment={shipment} recipientGifts={recipientGifts} />
      </Modal>
    </TablePage>
  );
};
