import { useQueryAllNewHireAssemblingGifts } from '../../../entities/gifts/queries';
import { TGiftStatuses } from '../../../generated/graphql';
import { GiftAssembling, TGiftAssemblingColumnDetails } from '../GiftAssembling/GiftAssembling';

export const newHireGiftsAssemblingColumnsDetails: TGiftAssemblingColumnDetails[] = [
  {
    name: 'Print Lego',
    destinationColumns: [TGiftStatuses.Assembling],
    nextPhase: TGiftStatuses.Assembling,
    id: TGiftStatuses.Todo,
  },
  {
    name: 'Assembling',
    destinationColumns: [TGiftStatuses.Todo, TGiftStatuses.Qa],
    nextPhase: TGiftStatuses.Qa,
    id: TGiftStatuses.Assembling,
  },
  {
    name: 'QA',
    destinationColumns: [TGiftStatuses.Todo, TGiftStatuses.Assembling, TGiftStatuses.Done],
    nextPhase: TGiftStatuses.Done,
    id: TGiftStatuses.Qa,
  },
  {
    name: 'Done',
    destinationColumns: [],
    nextPhase: undefined,
    id: TGiftStatuses.Done,
  },
];

export const NewHireGiftsAssembling = () => {
  return (
    <GiftAssembling columnsDetails={newHireGiftsAssemblingColumnsDetails} query={useQueryAllNewHireAssemblingGifts} />
  );
};
