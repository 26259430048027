import { Sidebar as SidebarComponent } from '@chocolate-soup-inc/cs-frontend-components';
import { generatePath } from 'react-router-dom';
import {
  ASSEMBLING_PATH,
  COMPANIES_PATH,
  EMPLOYEES_PATH,
  EVENTS_PATH,
  GIFTS_PATH,
  HOME_PATH,
  IMPORT_LIST_PATH,
  IMPORT_PATH,
  NEW_HIRES_PATH,
  NEW_PATH,
  OFFICE_PATH,
  SET_FOR_PICKUP_PATH,
  SHIPMENTS_PATH,
  SKIPPED_EVENTS_PATH,
  SUBSCRIPTIONS_PATH,
  TRACKING_PATH,
  UPCOMING_PATH,
} from '../../routes/paths';
import styles from './Sidebar.module.scss';

export const Sidebar = () => {
  return (
    <SidebarComponent
      className={styles.sidebar}
      sections={[
        {
          items: [
            {
              icon: 'domain',
              label: 'Companies',
              path: COMPANIES_PATH,
            },
            {
              icon: 'add_circle',
              label: 'Add new',
              path: generatePath(`${COMPANIES_PATH}/${NEW_PATH}`),
              indent: 1,
            },
            {
              icon: 'group',
              label: 'Employees',
              path: EMPLOYEES_PATH,
            },
            {
              icon: 'upload_file',
              label: 'Import Roster',
              path: generatePath(`${EMPLOYEES_PATH}/${IMPORT_PATH}`),
              indent: 1,
            },
            {
              icon: 'home_storage',
              label: 'Import History',
              path: generatePath(`${EMPLOYEES_PATH}/${IMPORT_LIST_PATH}`),
              indent: 1,
            },
          ],
        },
        {
          title: 'Upcoming Events',
          items: [
            {
              icon: 'event_repeat',
              label: 'Subscriptions',
              path: generatePath(`${EVENTS_PATH}/${UPCOMING_PATH}/${SUBSCRIPTIONS_PATH}`),
            },
            {
              icon: 'home',
              label: 'Home',
              path: generatePath(`${EVENTS_PATH}/${UPCOMING_PATH}/${SUBSCRIPTIONS_PATH}/${HOME_PATH}`),
              indent: 1,
            },
            {
              icon: 'domain',
              label: 'Office',
              path: generatePath(`${EVENTS_PATH}/${UPCOMING_PATH}/${SUBSCRIPTIONS_PATH}/${OFFICE_PATH}`),
              indent: 1,
            },
            {
              icon: 'group_add',
              label: 'New Hires',
              path: generatePath(`${EVENTS_PATH}/${UPCOMING_PATH}/${NEW_HIRES_PATH}`),
            },
            {
              icon: 'event_busy',
              label: 'Skipped Events',
              path: generatePath(`${EVENTS_PATH}/${UPCOMING_PATH}/${SKIPPED_EVENTS_PATH}`),
            },
          ],
        },
        {
          title: 'Gift Assembling',
          items: [
            {
              icon: 'event_repeat',
              label: 'Subscriptions',
              path: generatePath(`${GIFTS_PATH}/${ASSEMBLING_PATH}/${SUBSCRIPTIONS_PATH}`),
            },
            {
              icon: 'group_add',
              label: 'New Hires',
              path: generatePath(`${GIFTS_PATH}/${ASSEMBLING_PATH}/${NEW_HIRES_PATH}`),
            },
          ],
        },
        {
          title: 'Shipments',
          items: [
            {
              icon: 'local_shipping',
              label: 'Shipments',
              path: generatePath(SHIPMENTS_PATH),
            },
            {
              icon: 'local_post_office',
              label: 'Tracking',
              path: generatePath(`${SHIPMENTS_PATH}/${TRACKING_PATH}`),
            },
            {
              icon: 'inventory_2',
              label: 'Pickup',
              path: generatePath(`${SHIPMENTS_PATH}/${SET_FOR_PICKUP_PATH}`),
            },
          ],
        },
      ]}
      title='Internal Portal'
    />
  );
};
